import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { IoIosMail } from "react-icons/io"
import { RiPhoneFill, RiMapPin2Fill } from "react-icons/ri"
import {
  FaLinkedin,
  FaTiktok,
  FaYoutubeSquare,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare
} from "react-icons/fa"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import HubspotFormComet from "../components/common/HubspotFormComet"

import LayoutNew from "../components/layout-new"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const PageContactDemo = () => {
  const data = useStaticQuery(graphql`
    query PageContactDemoQuery {
      prismicGeneralSettings {
        data {
          phone
          tiktok
          youtube
          instagram
          linkedin
          email
          facebook
        }
      }
      prismicContactPage {
        uid
        _previewable
        data {
          contact_info_section_title
          contact_page_short_description
          contact_page_title
          contact_form_section_title
          contact_form_id
          location_section_title
          location_address
          location_address_url
          social_media_section_title
          contact_page_hero_background {
            gatsbyImageData(width: 1920)
          }
        }
      }
    }
  `)

  const mainDoc = data.prismicGeneralSettings
  const doc = data.prismicContactPage
  const imgHero = getImage(doc.data.contact_page_hero_background)
  const imgSrc = getSrc(doc.data.contact_page_hero_background)  

  return (
    <LayoutNew isContactPage>
      <Seo
        title={doc.data.contact_page_title}
        description={doc.data.contact_page_short_description}
        image={imgSrc}
        noIndex
      />
      <HeroSection title={doc.data.contact_page_title} imgSrc={imgHero} />

      <div className="bg-site-green-light py-12 md:py-24">
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col-reverse items-center md:flex-row md:items-start md:space-x-16 justify-center mx-auto">
          <div className="w-11/12 md:w-1/2 mb-16 md:mb-0 mx-auto">
            <div>
              <h2 className="text-2xl md:text-3xl font-extrabold border-b-2 border-site-gray-dark pb-3">
                {doc.data.contact_info_section_title}
              </h2>
              <div className="flex items-center space-x-3 mt-3">
                <RiPhoneFill
                  className="w-10 h-10 text-site-red"
                  aria-label="telephone"
                />
                <a
                  href={`tel:${mainDoc.data.phone}`}
                  className="text-2xl text-site-red font-bold hover:underline"
                >
                  {mainDoc.data.phone}
                </a>
              </div>
              <div className="flex items-center space-x-3 mt-3">
                <IoIosMail
                  className="w-10 h-10 text-site-red"
                  aria-label="email"
                />
                <a
                  href={`mailto:${mainDoc.data.email}`}
                  className="text-2xl text-site-red font-bold hover:underline"
                >
                  {mainDoc.data.email}
                </a>
              </div>
            </div>

            <div className="mt-24">
              <h2 className="text-2xl md:text-3xl font-extrabold border-b-2 border-site-gray-dark pb-3">
                {doc.data.social_media_section_title}
              </h2>
              <div className="flex items-center space-x-3 mt-3">
                <a
                  href={mainDoc.data.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="LinkedIn"
                  />
                </a>
                <a
                  href={mainDoc.data.tiktok}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok
                    className="w-9 h-9 bg-site-red text-white border border-white hover:border-site-red rounded p-1"
                    aria-label="TikTok"
                  />
                </a>
                <a
                  href={mainDoc.data.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutubeSquare
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="Youtube"
                  />
                </a>
                <a
                  href={mainDoc.data.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="Facebook"
                  />
                </a>
                <a
                  href={mainDoc.data.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="Instagram"
                  />
                </a>
                <a
                  href="https://twitter.com/SRA831b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitterSquare
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="Twitter"
                  />
                </a>
              </div>
            </div>

            <div className="mt-24">
              <h2 className="text-2xl md:text-3xl font-extrabold border-b-2 border-site-gray-dark pb-3">
                {doc.data.location_section_title}
              </h2>
              <div className="flex items-center space-x-3 mt-3">
                <a
                  href={`${doc.data.location_address_url}`}
                  className="text-lg font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiMapPin2Fill
                    className="w-10 h-10 text-site-red border border-white hover:border-site-red"
                    aria-label="Location"
                  />
                </a>
                <a
                  href={`${doc.data.location_address_url}`}
                  className="text-lg font-bold text-site-red hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {doc.data.location_address}
                </a>
              </div>
            </div>
          </div>

          <div className="w-11/12 md:w-1/2 relative bg-white flex flex-col items-center border-2 border-site-gray-dark rounded-3xl py-8 shadow-lg mx-auto mt-8 mb-16 md:mt-0 md:mb-0">
            <div className="absolute top-0 bg-site-green py-4 px-8 rounded-full -mt-8 mx-auto">
              <h2 className="text-white text-base md:text-xl lg:text-2xl uppercase font-bold text-center">
                {doc.data.contact_form_section_title
                  ? doc.data.contact_form_section_title
                  : "Section Title"}
              </h2>
            </div>

            <div className="w-10/12 md:w-8/12 mt-12">
              <HubspotFormComet
                portalId='23182726'
                formId="dfa38579-9c57-4e76-8e3d-5fd33b0f232e"
                loading={<div className='italic'>Loading contact form...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageContactDemo)
